var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.cartSearch},[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"accent darken-4","depressed":""},on:{"click":_vm.show_add_product_modal}},[_vm._v(" Добавить товары из каталога ")]),_c('SimpleDialog',{attrs:{"max-width":"1270"},on:{"close":_vm.close_add_product_modal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Добавление товаров каталога ")]},proxy:true},{key:"text",fn:function(){return [_c('Filters',{on:{"filter_update":_vm.load_and_upd_prods}}),_c('Table',{attrs:{"headers":_vm.is_main_warehouse ? _vm.cols : _vm.cols_other_warehouse,"items":_vm.all_products,"options":_vm.products_table_options,"loading":_vm.loading,"hide-default-footer":"","custom-sort":_vm.prevent_sort,"no-data-text":"Товары, удовлетворяющие выбранным параметрам, отсутствуют","not_resizeble":true},on:{"update:options":function($event){_vm.products_table_options=$event}},scopedSlots:_vm._u([{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(_vm.is_mobile ? 'width:100px' : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(
                  ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:_vm.$style.nomenclatureName},'div',attrs,false),on),[_c('span',{class:[_vm.$style.nomenclatureText, 'overflow-text']},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)]}},{key:"item.category_name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.category_name)+" ")]),(item.product_software_type)?_c('div',{class:_vm.$style.productCategoryDesc},[_vm._v(" "+_vm._s(item.product_software_type.name)+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.prices.actual.base.rub))+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"icon":"","type":"submit","color":"accent darken-4","disabled":_vm.find_in_cart(item) >= 0},on:{"click":function($event){return _vm.add_cart(item)}}},[_c('v-icon',[_vm._v("mdi-cart-outline")])],1)]}}],null,true)})]},proxy:true},{key:"actions",fn:function(){return [_c('Pagination',{class:_vm.$style.pagination,attrs:{"pagination_props":_vm.pagination_props,"hide_page_size":""},on:{"change_page":function($event){return _vm.upd_product_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_product_pagination('limit', $event)}}})]},proxy:true}]),model:{value:(_vm.add_product_modal),callback:function ($$v) {_vm.add_product_modal=$$v},expression:"add_product_modal"}}),_c('ProjectChoosenDialog',{on:{"close":function($event){_vm.cart_blocked_dialog = false}},model:{value:(_vm.cart_blocked_dialog),callback:function ($$v) {_vm.cart_blocked_dialog=$$v},expression:"cart_blocked_dialog"}}),_c('FrontolCheckDialog',{attrs:{"related_products":_vm.related_products,"product":_vm.product},on:{"upd_qty":_vm.upd_related_qty,"confirm":_vm.add_to_cart_confirmed}}),_c('SimpleDialog',{attrs:{"max-width":"564px","bts_align":"center","center_text":"","center_title":""},on:{"close":_vm.close_accreditation_dialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Внимание ")]},proxy:true},{key:"text",fn:function(){return [_c('div',{class:_vm.$style.link,domProps:{"innerHTML":_vm._s(_vm.accreditation_popup_message)}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","color":"accent darken-4"},on:{"click":_vm.close_accreditation_dialog}},[_vm._v(" Понятно ")])]},proxy:true}]),model:{value:(_vm.accreditation_message_dialog),callback:function ($$v) {_vm.accreditation_message_dialog=$$v},expression:"accreditation_message_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }